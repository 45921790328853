import React from 'react'
import PropTypes from 'prop-types'
import { LocaleContext } from '../components/layout/layout';
import { Container, Section } from '../styles/common/Layout';
import { A, H1, H1Content } from '../styles/common/Typo';
import { SEO } from '../components';

const ImpressumPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.pageImprint} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <Section id="section__imprint">
        <Container>
          <H1>Angaben gem&auml;&szlig; &sect; 5 TMG</H1>
          <p className="leading-relaxed mb-2">
            Marek Maras
            <br />
            Marek Maras - Frontend Webentwicklung
            <br />
            Rathausplatz 6
            <br />
            45525 Hattingen
          </p>
          <H1Content>Kontakt</H1Content>
          <p className="leading-relaxed mb-2">
            Telefon: +49 (0) 176 10 27 15 61
            <br />
            E-Mail: mail@marek-maras.de
          </p>
          <H1Content>Umsatzsteuer-ID</H1Content>
          <p className="leading-relaxed mb-2">
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
            <br />
            DE293857405
          </p>
          <H1Content>Bilquelle</H1Content>
          <p>Jake Owens <br /><A title="Jake Owens Instagram" href="https://www.instagram.com/jakeowensphoto/" rel="noreferrer"><span>@jakeowensphoto</span></A>
            <br />
            <A title="Jake Owens Instagram" href="https://www.eophoto.co/" rel="noreferrer"><span>www.eophoto.co</span></A></p>
          <H1Content>EU-Streitschlichtung</H1Content>
          <p className="leading-relaxed mb-2">
            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>
            .
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <H1Content>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</H1Content>
          <p className="leading-relaxed mb-2">
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
          <H1Content>Haftung f&uuml;r Inhalte</H1Content>
          <p className="leading-relaxed mb-2">
            Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p> <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <H1Content>Haftung f&uuml;r Links</H1Content>
          <p className="leading-relaxed mb-2">
            Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
          </p>
        </Container>
      </Section>
    </ >
  )
}

export default ImpressumPage;
